import React from 'react';
import cn from 'classnames';
import styles from './animatedTitle.scss';

interface IProps {
  sliderList: string[],
  className: string,
}

const AnimatedTitle = ({ sliderList, className }: IProps) => {
  const animationArray = [...sliderList, sliderList[0]]; // need for infinite animation

  return (
    <span className={ styles.sliderContainer }>
      <span className={ cn(styles.sliderList, styles[`sliderCount${ sliderList.length }`]) }>
        { animationArray.map((el, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span className={ className } key={ `${ el }-${ index }` }>{ el }</span>
        )) }
      </span>
    </span>
  );
};

export default AnimatedTitle;
