import React from 'react';
import Head from 'next/head';
import Favicon from '@/dataroom/ui/components/Landing/researchRoom/components/Favicon';
import ReadyToStart from '../../components/ReadyToStart';
import Intro from './Intro';
import Details from './Details';
import Feedbacks from './Feedbacks';
import Features from './Features';

const Home = () => (
  <>
    <Favicon />
    <Head>
      <title>Secure Virtual Data Room | ResearchRoom</title>
      <meta
        name="description"
        content="Secure document e-delivery service dedicated to IPO research, backed by fanatical 24x7 support."
      />
    </Head>
    <Intro />
    <Details />
    <Features />
    <Feedbacks />
    <ReadyToStart />
  </>
);

export default Home;
