import React from 'react';
import Tabs, { ITabList } from '../Tabs';

import styles from './features.scss';

interface IProps {
  actionButton?: React.ReactNode,
  tabsList: ITabList[],
  autoplay?: boolean,
}

const Features = ({ actionButton, tabsList, autoplay }: IProps) => (
  <>
    <Tabs tabsList={ tabsList } autoplay={ autoplay } />
    <div className={ styles.featuresButton }>{ actionButton }</div>
  </>
);

export default Features;
