import React from 'react';
import BaseFeedbacks from '@/Framework/UI/Pages/Landing/Sections/Feedbacks';
import SectionHeader from '@/Framework/UI/Pages/Landing/Sections/SectionHeader';
import { feedbacksList } from '@/dataroom/ui/components/Landing/dealvdr/feedbacksList';
import Section from '@/Framework/UI/Pages/Landing/Sections/Section';
import SectionContainer from '@/Framework/UI/Pages/Landing/Sections/SectionContainer';

const Feedbacks = () => (
  <Section>
    <SectionContainer size="large">
      <SectionHeader
        title="A trusted provider for retail transactions."
        description="FINSIGHT’s solutions, including Deal Roadshow, have helped hundreds of leaders reach the right people at key stages of their deals."
        maxWidth={ 875 }
      />
      <BaseFeedbacks
        cardStyle="modern"
        feedbacksList={ feedbacksList }
      />
    </SectionContainer>
  </Section>
);

export default Feedbacks;
