import React from 'react';
import cn from 'classnames';
import BookDemoButton from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModal/BookDemoButton';
import baseStyles from '@/dataroom/ui/components/Landing/researchRoom/tenantResearchRoomStyles.scss';
import styles from './startActions.scss';
import GetStartedButton from '@/dataroom/ui/components/Landing/common/GetStartedButton';
import FeatureToggle from '@/Framework/UI/Organisms/FeatureToggle/Components';
import { RESEARCHROOM_FEATURE } from '@/dataroom/application/config/featureToggle';

const StartActions = () => (
  <div className={ styles.actionsWrp }>
    <BookDemoButton className={ cn(baseStyles.actionButton, styles.bookDemoButton) } />
    <FeatureToggle
      featureName={ RESEARCHROOM_FEATURE }
    >
      <GetStartedButton className={ cn(baseStyles.actionButton, styles.getStartedButton) } />
    </FeatureToggle>
  </div>
);

export default StartActions;
